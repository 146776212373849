import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_klook_icon = _resolveComponent("klook-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createBlock(_component_a_dropdown, { class: "lang" }, {
    overlay: _withCtx(() => [
      _createVNode(_component_a_menu, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.LANGUAGE_LIST, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_menu_item, {
              key: index,
              "data-track-event": 'Common Page|System Laguage|' + item.eventName,
              onClick: ($event: any) => (_ctx.changeLang(item.value))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["data-track-event", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.className)
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('75588')) + " ", 1),
        _createVNode(_component_klook_icon, {
          type: "down",
          size: 16
        })
      ], 2)
    ]),
    _: 1
  }))
}