
import { defineComponent, reactive, toRefs } from 'vue'
import { LANGUAGE_LIST } from '@/common/js/constant/index'
import { getLocale } from '@/utils/core'
import { useRoute } from 'vue-router'
import KlookIcon from '@/components/icon.vue'

export default defineComponent({
  props: {
    className: String
  },
  components: {
    KlookIcon
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      LANGUAGE_LIST: LANGUAGE_LIST,
      dataTrackEventCommon: 'Common Page|System Laguage|'
    })

    const changeLang = (lang: string) => {
      if (lang === getLocale()) {
        return
      }

      const url = (lang === 'zh-TW' ? '' : `/${lang}`) + route.path
      let queryString = Object.keys(route.query)
        .map((key: string) => `${key}=${route.query[key]}`)
        .join('&')

      queryString = queryString ? `?${queryString}` : ''
      window.location.replace(url + queryString)
    }
    return {
      ...toRefs(state),
      changeLang
    }
  }
})
